@font-face {
    font-family: 'multicase-icons';
    src: url('/fonts/multicase-icons.eot?19959973');
    src: url('/fonts/multicase-icons.eot?19959973#iefix') format('embedded-opentype'),
         url('/fonts/multicase-icons.woff2?19959973') format('woff2'),
         url('/fonts/multicase-icons.woff?19959973') format('woff'),
         url('/fonts/multicase-icons.ttf?19959973') format('truetype'),
         url('/fonts/multicase-icons.svg?19959973#multicase-icons') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display:block;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'multicase-icons';
    src: url('../font/multicase-icons.svg?19959973#multicase-icons') format('svg');
  }
}
*/
 
[class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "multicase-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
 
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
 
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
 
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
 
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
 
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
 
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
 
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-help-circled:before { content: '\e800'; } /* '' */
.icon-star:before { content: '\e801'; } /* '' */
.icon-cancel:before { content: '\e802'; } /* '' */
.icon-cancel-circled:before { content: '\e803'; } /* '' */
.icon-truck:before { content: '\e804'; } /* '' */
.icon-attention:before { content: '\e805'; } /* '' */
.icon-left-open:before { content: '\e806'; } /* '' */
.icon-right-open:before { content: '\e807'; } /* '' */
.icon-heart:before { content: '\e808'; } /* '' */
.icon-basket:before { content: '\e809'; } /* '' */
.icon-search:before { content: '\e80a'; } /* '' */
.icon-heart-empty:before { content: '\e80b'; } /* '' */
.icon-ok:before { content: '\e80c'; } /* '' */
.icon-location:before { content: '\e80d'; } /* '' */
.icon-down-open:before { content: '\e80e'; } /* '' */
.icon-up-open:before { content: '\e80f'; } /* '' */
.icon-user:before { content: '\e810'; } /* '' */
.icon-key:before { content: '\e811'; } /* '' */
.icon-arrows-cw:before { content: '\e812'; } /* '' */
.icon-ok-circled2:before { content: '\e813'; } /* '' */
.icon-login:before { content: '\e814'; } /* '' */
.icon-logout:before { content: '\e815'; } /* '' */
.icon-th-large:before { content: '\e816'; } /* '' */
.icon-th:before { content: '\e817'; } /* '' */
.icon-th-list:before { content: '\e818'; } /* '' */
.icon-t-shirt:before { content: '\e819'; } /* '' */
.icon-help-circled-alt:before { content: '\e81a'; } /* '' */
.icon-trash:before { content: '\e81b'; } /* '' */
.icon-pencil:before { content: '\e81c'; } /* '' */
.icon-star-empty:before { content: '\e81d'; } /* '' */
.icon-menu-1:before { content: '\f0c9'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-sitemap:before { content: '\f0e8'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-desktop:before { content: '\f108'; } /* '' */
.icon-laptop:before { content: '\f109'; } /* '' */
.icon-tablet:before { content: '\f10a'; } /* '' */
.icon-mobile:before { content: '\f10b'; } /* '' */
.icon-spinner:before { content: '\f110'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-info:before { content: '\f129'; } /* '' */
.icon-ellipsis-vert:before { content: '\f142'; } /* '' */
.icon-left-circled2:before { content: '\f190'; } /* '' */
.icon-building-filled:before { content: '\f1ad'; } /* '' */
.icon-user-plus:before { content: '\f234'; } /* '' */
.icon-television:before { content: '\f26c'; } /* '' */
.icon-industry:before { content: '\f275'; } /* '' */
.icon-user-o:before { content: '\f2c0'; } /* '' */