/* ------------------------------
	// Payments 
------------------------------ */

.payments {
	margin-top: 2em;
}

.payments .customer-registration-pr-bs {	
	margin-top: 1em;
	width: 100%;
	display: inline-block;
}


.payments .customer-registration .btn-group {
	width: 100%;
}

.payments .customer-registration .btn-group .mc-btn {
	width: 50%;
}

.payments .req-info {
	text-align: right;
}

.payments .requiredMsg {
  margin-bottom: -1.5em;
  float: left;
  font-size: 0.8em;
  margin-top: 1em;
}

.payments .tab-content {
	background-color: #fff; 
	border-left: 1px solid #ddd; 
	border-right: 1px solid #ddd; 
	border-bottom: 1px solid #ddd; 
	padding: 0;  
	overflow: hidden;
	-ms-border-radius: 0 0 4px 4px; 
	border-radius: 0 0 4px 4px;
}

.payments li {
	vertical-align: top;
}

.payments .nav-tabs {
	/*background-color: #F9F9F9;*/
	background-color: #fff;
	border-right: 1px solid #ddd;
	border-top: 1px solid#ddd; 
	border-left: 1px solid #ddd; 
	-ms-border-radius: 3px;
	border-radius: 3px;	
	overflow: hidden;
}

.customer-registration .delivery-address {
	margin: 1.2em;
}

.payments .nav-tabs .payment-nav-logo {
	min-height: 32px;
	max-height: 32px;
	float: left;
}

.payments .nav-tabs .col-xs-3 {
	padding: 0;
}

.payments .nav-tabs .payment-nav-text {
	font-weight: normal;
	padding-top: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	margin-bottom: 5px;
}

.payments .nav-tabs.nav-justified > li:hover {
	border-radius: 0;
	-ms-border-top-left-radius: 15px;
	border-top-left-radius: 15px;
	-ms-border-top-right-radius: 15px;
	border-top-right-radius: 15px;
}

.payments .nav-tabs.nav-justified > li > a {
	text-align: left;
	border: none;
	margin: 0 -15px;
	min-height: 52px;
	background-color: #f5f5f5;
}


.payments .nav-tabs.nav-justified > li.active:first-child > a {
	border-left: 0;	

}

.payments .nav-tabs.nav-justified > li:last-child {
	border-bottom: 1px solid #ddd;
	margin: 0 -15px;
	padding: 0 30px;
}



.payments .nav-tabs.nav-justified > li.active:last-child > a {
	border-right: 0;
	padding: 10px 15px;
}





.payments .payment-logo-txt {
	max-height: 32px;
	min-height: 32px;
	font-size: 1em;
	overflow: hidden;
	margin-bottom: -6px;
	white-space: nowrap;
	padding: 6px 0;
}

/*.payments a.payment-nav-link-94 div,
.payments a.payment-nav-link-95 div,
.payments a.payment-nav-link-107 div,
.payments a.payment-nav-link-114 div{
	margin-top: 6px;
	margin-bottom: -6px;
}

.payments a.payment-nav-link-114 {
	border-left-width: 0;
	border-right-width: 0;
}*/

.payments .payment-logo {
	max-height: 24px;
	width: auto;
}

.payments #payment-logo-87 {
	max-height: 33px;
}
.payments #payment-logo-128 {
	max-height: 33px;
}

.payments .nav-tabs.nav-justified > li {
	border-bottom: 1px dashed #fff;
	margin: 0 15px;
	min-height: 52px;
}

.payments .nav-tabs.nav-justified > li.active > a {
	margin: -1px -16px;
	border-radius: 0;
	border: 1px solid #ddd;
	background-color: #fff	
}

.payments .payment-nav-select {
	width: 16.666667%;
	position: relative;
	min-height: 1px;
	padding-right: 15px;
	padding-left: 5px;
	float: left;
	margin-top: 7px;
}

.payments .orgnrssn-content {
	margin-top: 1em;
}

.payments .gothia-content {
	margin-top: 1em;
}

.payments .svea-checkout-content {
	margin-top: 1em;
}

.payments .nets-easy-checkout-content {
    margin-top: 1em;
}

.payments .klarna-checkout-content {
    margin-top: 1em;
}

.payments #klarna-checkout-snippet-error {
	display: none;
}

/* Santander */ 
.payments .santander-content {
	width: 100%;
	float: left;
}
.checkout-select select {
	padding-top: 11px;
	padding-bottom: 0;
}
.checkout-select label {
	display: block !important;
}
.payments .santander-content .smart-label {
	/*margin-bottom: -1em;*/
}
.santander-content .form-control {
	height: 38px;
}
.santander-monthlycost {
	min-height: 48px;
}
.santander-content .left-aligned {
	padding-right: 0;
}
/*.payments #santander-invoice .smart-label i {
    right: 35px;
}
.payments #santander-partpayment .smart-label i {
    right: 35px;
}*/
/*.payments #santander-invoice img {
	position: relative;
	top: -3px;
}
.payments #santander-partpayment img {
	position: relative;
	top: -3px;
}
.payments #santander-invoice .smart-label .active,
.payments #santander-invoice .smart-label .inactive {
	position: relative;
}
.payments #santander-partpayment .smart-label .active,
.payments #santander-partpayment .smart-label .inactive {
	position: relative;
}
.payments #santander-invoice .smart-label .active label,
.payments #santander-invoice .smart-label .inactive label {
	left: 5px;
	top: 3px;
}
.payments #santander-partpayment .smart-label .active label,
.payments #santander-partpayment .smart-label .inactive label {
	left: 5px;
	top: 3px;
}*/

/* SveaWebPay */ 
.sveawebpay-content .form-control {
	height: 38px;
}

.payments #gothia a {
	color: #0080c9;
	text-decoration: underline;
	margin-top: 10px;
	cursor: pointer;
}

.payments #gothia .conditions {
	margin-top: 10px;
}

.payment-title {
    position: relative;    
    margin: 10px 0 10px 0;
    top: 10px;
}

.payments .not-you {
	display: block!important;
}

.payment-title-txt > span:first-child {
    font-weight: bold;
}

.payments #gothia .smart-label .active, .payments #gothia .smart-label .inactive {
	position: relative;
}

.payments #gothia .smart-label .active label, .payments #gothia .smart-label .inactive label {
	left: 5px;
	top: 3px;
}

#gothia .reg-textbox {
	margin-top: 0;
	height: 38px;
}

#OrgnrSsn .form-control {
    height: 38px;
}

.orgnrssn-conditions .smart-label{
	margin: 0 20px 0 5px;
}

.payments .vipps-express-checkout-button {
	background-image: url(/css/images/vipps_ec_sq.svg) !important;
	background-size: contain;
	height: 34px;
	background-repeat: no-repeat;
	width: 193px;
	background-color: transparent !important;
	border: none;
}

.payments .vipps {
	clear: both;
	padding-top: 15px; 
}

.payments .vipps-content {
	text-align: center;
	padding: 40px;
}

/* ------------------------------
	// END - Payments
------------------------------ */





/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
}

/*Mobile device < 768 */
@media only screen and (max-width : 767px) {

	.customer-registration .delivery-address .col-sm-4,
	.customer-registration .delivery-address .col-sm-6,
	.customer-registration .delivery-address .col-sm-8,
	.customer-registration .delivery-address .col-sm-12 {
		padding: 0;
	}
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

	.payments .customer-registration-pr-bs {
		max-width: 12em;
	}
	
	.payments .nav-tabs {
		background-color: #f5f5f5;
	}
	
	.payments .nav-tabs .payment-nav-logo {
		float: none;
	}

	.payments .nav-tabs.nav-justified > li {
		margin: 0;
		border: none;
		min-height: 0;
		border-bottom: 1px solid #ddd;
	}

	.payments .nav-tabs.nav-justified > li.active {
		border-bottom: none;
	}
	
	.payments .nav-tabs.nav-justified > li > a {
		text-align: center;	
		border: none;
		margin: 0;
		min-height: 0;
	}

	.payments .nav-tabs.nav-justified > li.active > a {
		margin: 0;
		border-radius: 4px 4px 0 0;
		border-bottom: none;
		border-top: none;
		background-color: #fff;
		padding-bottom: 11px;
		min-height: 60px;
	}



	.payments .nav-tabs.nav-justified > li:last-child {
		border-bottom: 1px solid #ddd;
		margin: 0;
		padding: 0;
	}

	.payments .nav-tabs.nav-justified > li.active:last-child {
		border-bottom: 0;
	}


	.payments .requiredMsg {
		float: right;
		font-size: 1em;
	}

	.payments .nav-tabs .payment-nav-text {
		color: #666;
		font-size: 0.8em;
	}

	.payments .payment-nav-select {
		position: absolute;
	}

	.payments .tab-content {
		min-height: 250px;
	}

	.orgnrssn-conditions .smart-label{
		margin: 0;
	}

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/

.checkout label.svea-plan-name {
	font-weight: 500;
}

.svea-terms-container {
	margin-bottom: 0.5em;
}

.svea-orgpers-no-container {
	margin-bottom: 0.5em;
}