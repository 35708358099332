﻿/*This file is in use by D4 but not D2016*/
$lineColor: #ccc;
$lineChopMobile: 0px;
$lineChop: 150px;

.cart-underline {
    border: none;
    padding-bottom: 1px;
    background-image: linear-gradient(0deg, $lineColor, $lineColor);
    background-repeat: no-repeat;
    background-size: calc(100% - #{$lineChopMobile}) 1px;
    background-position: $lineChopMobile 100%;

    @media (min-width: 768px) {
        background-size: calc(100% - #{$lineChop}) 1px;
        background-position: $lineChop 100%;
    }
}

.cart-topline {
    border: none;
    padding-top: 1px;
    background-image: linear-gradient(90deg, $lineColor, $lineColor);
    background-repeat: no-repeat;
    background-size: calc(100% - #{$lineChopMobile}) 1px;
    background-position: $lineChopMobile 0px;

    @media (min-width: 768px) {
        background-size: calc(100% - #{$lineChop}) 1px;
        background-position: $lineChop 0px;
    }
}

.cart-double-underline {
    border: none;
    padding-bottom: 3px;
    background-image: linear-gradient(0deg, $lineColor 0px, $lineColor 1px, transparent 1px, transparent 2px, $lineColor 2px, $lineColor 3px);
    background-repeat: no-repeat;
    background-size: calc(100% - #{$lineChopMobile}) 3px;
    background-position: $lineChopMobile 100%;

    @media (min-width: 768px) {
        background-size: calc(100% - #{$lineChop}) 3px;
        background-position: $lineChop 100%;
    }
}

.cart-topline.cart-double-underline {
    border: none;
    padding-bottom: 3px;
    padding-top: 1px;
    background-image: linear-gradient(0deg, $lineColor, $lineColor), linear-gradient(0deg, $lineColor 0px, $lineColor 1px, transparent 1px, transparent 2px, $lineColor 2px, $lineColor 3px);
    background-repeat: no-repeat no-repeat;
    background-size: calc(100% - #{$lineChopMobile}) 1px, calc(100% - #{$lineChopMobile}) 3px;
    background-position: $lineChopMobile 0px, $lineChopMobile 100%;

    @media (min-width: 768px) {
        background-size: calc(100% - #{$lineChop}) 1px, calc(100% - #{$lineChop}) 3px;
        background-position: $lineChop 0px, $lineChop 100%;
    }
}
