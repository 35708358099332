/* ------------------------------
	// Responsive Tables
	------------------------------ */
	
@media only screen and (max-width: 767px) {
    
    /* Force table to not be like tables anymore */
	.rs-table table, 
	.rs-table thead, 
	.rs-table tbody, 
	.rs-table th, 
	.rs-table td, 
	.rs-table tr { 
		display: block; 
	}

	.rs-table table.table-bordered {
		padding: 0;
	}
 
	/* Hide table headers (but not display: none;, for accessibility) */
	.rs-table thead tr { 
		position: absolute;
		top: -9999px;
		left: -9999px;
	}
 
	.rs-table tr { border: 1px solid #ccc; }
 
	.rs-table td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
		padding-left: 50%; 
		white-space: normal;
		text-align:left;		
	}
 
	.rs-table td:before { 
		/* Now like a table header */
		position: absolute;
		/* Top/left values mimic padding */
		top: 6px;
		left: 6px;
		width: 45%; 
		padding-right: 10px; 
		white-space: nowrap;
		text-align:left;
		font-weight: bold;
	}
 
	/* 	Label the data 	*/
	.rs-table td:before { content: attr(data-title); }

	.rs-table tbody td,
	.rs-table tbody td > div {
		text-align: right;
	}
}
