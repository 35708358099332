/* ------------------------------
	// Ski assembly
	------------------------------ */

.skias {
    padding: 15px;
}

.skias-content {
    margin-right: -15px;
    margin-left: -15px;
}

.skias-info {
    display: none;
}

.skias-header,
.skias-info-header {
    font-size: 14pt;
    font-weight: bold;
    padding-bottom: 0.5em;
}

.skias-mount-type {
    margin-bottom: 15px;
}

.skias-mount-group.diable-mount {
    opacity: 0.3;
    display: none;
}

.skias-info-group {
    margin-top: 25px;
    border: 1px solid #eee;
    border-radius: 3px;
    padding: 15px;
}

.diable-info-group {
    opacity: 0.3;
}

.skias-mount-to {
    border: 1px solid #eee;
    border-radius: 3px;
    padding: 15px;
}

    .skias-mount-to.checkbox.mc-ui label {
        padding-left: 0;
        font-weight: bold;
    }

.skias-or {
    width: 100%;
    height: 11px;
    border-bottom: 1px solid #eee;
    text-align: center;
    display: none;
}

    .skias-or span {
        background-color: #fff;
        padding: 0 10px;
    }

.skias-mount-pos {
    display: inline-block;
    width: 100%;
}

img.skias-info-img,
img.skias-info-img2 {
    width: 100px;
    float: left;
}

.skias-info-text-more {
    margin-top: 1em;
}

.skias-mount-info {
    margin: 1em 0;
    color: red;
    font-size: 0.8em;
}

.skias-mount-size,
.skias-chk-header {
    font-weight: bold;
}

/*.skiassembly-textarea {
  margin: 1em 0 -1em 0;
}*/

.skias-info-img-txt {
    margin-bottom: 5.5em;
}

.skias-pos {
    margin: 1em 0;
    width: 100%;
}

.text-center a.btn-primary {
    margin: 1em 0 1.5em;
    width: 145px;
}

.skias-layer {
    height: 100%;
    width: 100%;
    z-index: 99999;
    position: absolute;
    display: none;
}

.boosize-input {
    border-radius: 0 4px 4px 0;
}

.skias-chk-header {
    border-top: 1px solid #eee;
    padding-top: 1em;
}

.skias-confirm .btn.btn-link {
    text-decoration: underline;
}

.skias-mount-right {
    display: inline-block;
}

.skias-confirm-bottom .btn-cancel {
    margin-top: -15px;
}


/* ------------------------------
	// END - Ski assembly
	------------------------------ */




/* ------------------------------
// Ski Attribute assembly Module
------------------------------ */

.skiat .form-control {
    margin-top: 15px;
}

.skiat .skias-header {
    margin-top: 15px;
}

/* ------------------------------
	// END - Ski Attribute assembly Module
	------------------------------ */

/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

    .skias-content {
        margin-right: 0;
        margin-left: 0;
    }

    .skias-mount-group.diable-mount {
        display: block;
    }

    .skias-layer {
        display: block;
    }

    .skias-mount-info {
        font-size: 1em;
    }

    .col-sm-6.skias-mount-right {
        display: inline-block !important;
    }

    .skias-pos {
        margin: 0.5em 0;
        width: auto;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/
