.checkbox.mc-ui label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both;
}

.checkbox.mc-ui .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 1px solid #a9a9a9;
    border-radius: .25em;
    width: 1.3em;
    height: 1.3em;
    /*float: left;*/
    margin-right: .5em;
    margin-bottom: -0.3em;
}

.radio .cr {
    border-radius: 50%;
}

.checkbox.mc-ui .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: 11px;
    line-height: 0;
    top: 50%;
    left: 10%;
}

.radio .cr .cr-icon {
    margin-left: 0.04em;
}

.checkbox.mc-ui label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox.mc-ui label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
    transform: scale(3) rotateZ(-20deg);
    opacity: 0;
    transition: all .3s ease-in;
}

.checkbox.mc-ui label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
    transform: scale(1) rotateZ(0deg);
    opacity: 1;
}

.checkbox.mc-ui label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
    opacity: .5;
}

/*input[type="text"].input-comment {
    position: relative;
    width: 75%;
    top: inherit;
    max-width: 20em;
    margin-left: 2em;
    margin-right: initial;
    margin-top: initial;
    margin-bottom: initial;
}*/
