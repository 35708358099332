/* ------------------------------
	// *** Warehouse Sale ***
	// 
	// Template for Lagersalg
	------------------------------ */
.sale {
    width: 100%;
}

.sale-heading {
  background: #efefef;
  font-weight: bold;
  padding: 10px 0;
}

.sale h1.sale-header {
    display: none;
}

.sale-item {
  background-color: #fff;   
  border-bottom: 1px solid #ccc;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sale-desc {
  text-align: left;
/*  padding: 0.5em 0.2em; */
  padding-right: 0.5em;
  color: #333;  
}

 .sale-desc1 {
  font-weight: bold;   
 }

 .sale-desc1,
 .sale-desc2 {
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;  
 }
 
.sale-price-all {
  color: #333;
  font-weight: normal;
  padding: 20px 0 0 0;
  white-space: nowrap;
}

.sale-ext-price-txt {
  display: none;
}
.sale-ext-price {
  text-decoration: line-through;
  color: #999;
}
.sale-price {
  font-weight: bold;
}

 .sale-stock {
  vertical-align: bottom;
  line-height: 20px;
}

 .sale .btn.btn-primary {
  color: #fff;
  padding-right: 10px;
}
@media (min-width: 992px) {
    .sale .btn.btn-primary {
        margin-top: 0.5em;
    }
}


/* ------------------------------
	// END
	------------------------------ */