/* ------------------------------
	// Small Cart / Liten Handlevogn
	------------------------------ */

.header-left.pull-left {
	min-height: 5em;
}

.cart-small-load {
	min-width: 5px;
	display: inline-block;
	text-align: center;
}

.small-car-panel .panel.panel-default {
	box-shadow: 0 0 10px 4px #888;
	box-shadow: 0 0 10px 4px rgba(0,0,0,0.5);
}

a.cart-small-trigger.btn.btn-default,
a.cart-small-goto-checkout.btn.btn-primary {
	border-radius: 3px;
	font-size: 14px;
	line-height: 18px;
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 767px) {
	.btn-group > .cart-small-trigger.btn:first-child:not(:last-child):not(.dropdown-toggle) {
		border-radius: 4px;
	}
}

.cart-small-togle-open .caret {
	-webkit-transform: scaleY(-1);
	-moz-transform: scaleY(-1);
	-ms-transform: scaleY(-1);
	-o-transform: scaleY(-1);
	transform: scaleY(-1);
}

.cart-small-icon {
	/*background: transparent url(/App_Themes/Demonstrare2MASTER/Images/cart_icon.png) no-repeat;*/
	padding: 0 10px;
	margin: 0;
}

.cart-small-count {
	color: #333;
}

.cart-small-popover.popover {
	min-width: 20em;
    max-width: none;
}

.popover.cart-small-popover .panel-body {
	padding: 0;
}

.popover.cart-small-popover.bottom {
	/*width: 100% !important;*/
	/*top: 3.3em !important;*/
	margin: 0;
	float: right;
}

.cart-small-popover .popover-title {
	margin-top: -1px;
	padding: 0.6em 0.5em 0.3em 1em;
}

.cart-small-popover.popover.bottom > .arrow:after {
	border-bottom-color: #f7f7f7;
}

.cart-small-popover .popover-title .close {
	margin: -0.2em 0.3em 0 1.5em;
}

.cart-small-items img {
    cursor: pointer;
}

.cart-small-items .col-amount-xs {
    padding-left: 5px;
    padding-right: 5px;
}

.cart-small-items .cart-desc,
.cart-small-items .col-amount-xs,
.cart-small-items .cart-price {
    display: table;
    height: 48px;
}

    .cart-small-items .cart-price {
        text-align: right;
    }

    .cart-small-items .cart-desc .cart-item-header,
    .cart-small-items .col-amount-xs .cart-item-quantity,
    .cart-small-items .cart-price .col-price,
    .cart-small-items .cart-price .col-delete {
        display: table-cell;
        vertical-align: middle;
    }

.cart-small-items .col-price {
}

.cart-small-items .col-delete {
    font-size: 20px;
    width: 32px;
}

.cart-small-items .cart-item-header {
	cursor: pointer;
    padding-left: 78px;
    text-align: left;
}

.cart-small-items .cart-desc img {
    position: absolute;
    width: 48px;
}

.popover.cart-small-popover .popover-title .pull-left {
    margin-left: 1em;
}

.popover.cart-small-popover .popover-title .pull-right {
	margin-right: 1em;
}

.popover.cart-small-popover .row-line {
	border-bottom: 1px dashed #ddd;
	margin: 5px 0px 5px 0;
}

.popover.cart-small-popover .row-line.last-row {
	border-bottom-style: solid;
}
.popover.cart-small-popover .cart-small-total{
    padding-bottom:2em;
}
.popover.cart-small-popover .cart-small-total-txt {
    font-weight: bold;
    font-size: 16px;
    margin: 0.5em 35px 0 0;
}

.popover.cart-small-popover .cart-small-total-discount {
    font-weight: bold;
    font-size: 12px;
    margin: 0.5em 35px 0 0;
}

.popover.cart-small-popover .cart-small-total-after-discount-txt {
    font-weight: bold;
    font-size: 16px;
}

.popover.cart-small-popover .cart-small-total-after-discount-value {
    font-weight: bold;
    font-size: 16px;
}


.popover.cart-small-popover .cart-small-total-txt {
    font-weight: bold;
    font-size: 16px;
    margin: 0.5em 35px 0 0;
}

.popover.cart-small-popover .panel-footer {
    background: #fff;
    border-top: none;
}

.cart-small-vat {
    padding-left: 1em;
    margin-right: -1em;
    padding-top: 0.6em;
}

.cart-small-fav {
    display: inline-block;    
}

.cart-small {
    display: inline-block;
}

.cart-small-links {
	margin-top: 1.4em;
	margin-bottom: 1em;
	text-align: center;
	padding-right: 5px;
}

.cart-small-trigger.cart-small-has-item {
	min-width: 10em;
}

.cart-small-trigger.btn.btn-default:active,
.cart-small-trigger.btn.btn-default:focus {
	outline: none;
}

.cart-small-links-tocart,
.cart-small-links-tocheckout {
	text-transform: uppercase;
	color: #8a8a87;
}

.cart-small-product,
.cart-small-count,
.cart-small-product-txt,
.cart-small-empty,
.cart-small-goto-checkout.btn.btn-primary {
	display: none;
}

.cart-small-trigger.btn.btn-default {
	min-width: 3em;
}

.cart-small-count:before {
	content: "(";
}

.cart-small-count:after {
	content: ")";
}

.cart-small-togle-icon {
	padding: 0 0 0 0.5em;
}

.small-cart-empty-items {
	margin: 0 0 0.5em 0;
}

.cart-show-all-items {
    padding-top: 5px;
    display: inline-block;
    text-align: center;
    width: 100%;
}

.cart-orderbook{
    min-width:100px;
    max-width:200px;
}
.cart-orderbook i.glyphicon{
    float:right;
}
    .cart-orderbook.closed {
        max-height: 40px;
        overflow-y: hidden;
        overflow-x: hidden;
    }

.cart-orderbook.open {
    max-height: 350px;
    overflow-y:scroll;
    overflow-x:hidden;
}
.cart-orderbook.closed .cart-orderbook-item{
    display:none;
}

.cart-orderbook.closed .cart-orderbook-item:first-of-type {
    display: block;
}

    .cart-orderbook.open .cart-orderbook-item {
        display: block;
    }

.cart-orderbook-item.selected, .cart-orderbook-item:hover {
    background-color: whitesmoke;
    cursor: pointer;
}

.cart-small-invoicefee-block {
    display: block;
    clear: both;
}
.cart-small-invoicefee-text {
    font-weight: normal;
    font-size: 14px !important;
}
.cart-small-invoicefee {
    font-weight: normal;
    font-size: 14px !important;
   
}


body.login-type-Oci a.cart-small-tocheckout{
    display:none !important;
}

@media (max-width: 768px) {
    .cart-small-offer {
        display: none;
    }

    .cart-small-offer-container {
        display: none;
    }

    .btn-offer-cart {
        padding: 0px 2px;
        margin-bottom: 0;
        font-size: 27px;
    }
}

@media (min-width: 768px) {
    .cart-small-offer {
        display: inline-block;
    }

    .cart-small-offer-container {
        display: inline-block;
    }

    .offer-cart-text {
        margin-left: -18px !important;
        color: white;
    }

    .btn-offer-cart.btn-link:hover,
    .btn-offer-cart.btn-link:focus {
        text-decoration: none;
    }

    .btn-offer-cart {
        padding: 3px;
        margin-bottom: 0;
        font-size: 27px;
    }

    a.btn-offer-cart.btn.btn-link::after {
        font-size: 14px;
        vertical-align: top;
        position: relative;
        top: 8px;
    }
}
/* ------------------------------
	// END - Small Cart / Liten Handlevogn
	------------------------------ */
/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/
/*==========  Mobile First Method  ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width: 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {

    .cart-small-count,
    .cart-small-goto-checkout.btn.btn-primary {
        display: inline;
    }

    .cart-small-trigger.btn.btn-default {
        max-width: none;
        min-width: 0;
    }

    .cart-small-icon {
        margin-right: 0.5em;
    }

    .cart-small-count:before {
        content: "";
    }

    .cart-small-count:after {
        content: "";
    }

    .cart-small-togle-icon.pull-right {
        float: right !important;
        padding-left: 1em;
    }

    .cart-small .popover.cart-small-popover.bottom {
        /*width: 20em !important;*/
        min-width: 20em;
        margin: 0.7em 0 0 0;
        float: none;
    }

    /*.cart-small.btn-group .popover.cart-small-popover.bottom*/
    .popover.cart-small-popover.bottom {
        width: 570px !important;
        min-width: 570px;
        float: none;
    }
}

/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {

	a.cart-small-trigger.btn.btn-default,
	a.cart-small-goto-checkout.btn.btn-primary {
		font-size: 1.2em;
		line-height: 1.8em;
	}

	.cart-small-product-txt,
	.cart-small-product,
	.cart-small-empty {
		display: inline;
	}

	.cart-small-load {
		min-width: 245px;
		display: inline-block;
		text-align: center;
	}

	.cart-small.btn-group .popover.cart-small-popover.bottom {
		margin: 0.7em 0 0 -2em;
	}
}

/* Large Devices, Wide Screens */
@media only screen and (min-width: 1200px) {
}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/
