﻿.twofa-code > div {
    margin-bottom:10px;
    min-height:20px;
}

.login-2fa-reg img.store-badge{
    width:190px;
}
#login-2fa-reg .logo-2fa-google, #login-2fa-reg .logo-2fa-apple{
    text-align:center;
}


/* Extra Small Devices, Phones */
@media only screen and (min-width : 320px) {
    #login-2fa-reg .input-group-addon.manual-entry {
        display: none;
    }
    #twofa-manualentrykey {
        font-size: 13px;
    }
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
    #login-2fa-reg .input-group-addon.manual-entry {
        display: table-cell;
    }

    #twofa-manualentrykey {
        font-size: 16px;
    }
   
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 767px) {
    .modal.d4-popup.two-factor-modal .modal-body #lightbox-placeholder.center-placeholder{
        max-height:100vh;
    }
}


@media only screen and (min-width : 992px) {
    .modal.two-factor-modal .modal-lg{
        width:600px;
    }
    
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}

.d4-popup.two-factor-modal .modal-body #lightbox-placeholder.center-placeholder{
    max-height:100vh;
}

.center-qr-code{
    width:171px;
    margin-left:auto;
    margin-right:auto;
}

.center-scan-text {
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
}

.skip-two-factor{
    margin-top:10px;
}
#login-2fa-verify .twofa-code p{
    margin-bottom:10px;
}
.change-password-form span.link {
    text-decoration: underline;
    color: #62a0c5;
    cursor:pointer;
}