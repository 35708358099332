/* Mobile First */

/* ------------------------------
	// Bootstrap change
	------------------------------ */

input[type="radio"] {
	margin-top: 1px;
}

input[type="checkbox"] {
	margin-top: 2px;
}

/* Set Default*/
.center-block {
	text-align: center;
}

.panel-heading .collapsed .icon-togle:after {
	content: "\e114";
}

.panel-heading .icon-togle:after {
	font-family: 'Glyphicons Halflings';
	content: "\e113";
	float: right;
	color: grey;
	margin: 9px 0 -30px -5px;
	font-size: 1em;
}

body.modal-open {
	overflow: hidden;
    position: static;
}

.smart-label .input-group.active label,
.smart-label .input-group.inactive label {
	top: 1px;
	left: 5px;
}

.smart-label .input-group.active .form-control {
	padding: 12px 5px 0 10px;
}

.smart-label .input-group.inactive .form-control,
.smart-label .input-group.inactive-old .form-control {
	padding: 6px 12px;
}

.free-shipping-counter {
	left: -143px;
	position: absolute;
	top: 20px;
	width: 125px;
	height: 50px;
}

/* ------------------------------
			// Bootstrap change to OLD Design (Demonstrare)
			------------------------------ */
.body-cart #PageColumnLeft,
.body-checkout #PageColumnLeft,
.body-checkout #PageColumnRight {
	display: none;
}

.body-cart #PageContainer,
.body-checkout #PageContainer {
	border-left: 20px;
	max-width: 100%;
}

.body-checkout #PageContainer {
	border-right: 20px;
}

.body-cart #PageColumnCenter,
.body-checkout #PageColumnCenter,
.body-cart #PageColumnCenter .ColumnCenterWithSidebarLeft,
.body-checkout #PageColumnCenter .ColumnCenterWithSidebarLeft {
	padding: 0;
	width: 100%;
}
/* ------------------------------
			// /END - Bootstrap change to OLD Design (Demonstrare)
			------------------------------ */

/* ------------------------------
	// /END - Bootstrap change
	------------------------------ */


@media only screen and (min-width: 320px) and (max-width:991px) {
	
	/*Show buy button in annonselisting*/
	.Layout2Element a.AddBuyButton {
		display: none !important;
	}
	.Layout2Element .InfoOverlay {
		padding: 0 5px;
	}
	.Layout2Element .AddHeaderContainer {
		margin-bottom: 0;
	}

}



/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/
