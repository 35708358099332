.reset-password {
	padding: 50px;
	box-sizing: border-box;
	overflow: auto;
}
.reset-password label {
	margin-top: 12px;
}
.reset-password .form-group {
	overflow: auto;
}

#reset-result-error, 
#reset-result-validation-error,
#reset-result-avs-not-selected {
  color: red;
  margin-top: 20px;
  font-size: 16px;
}

#reset-result-success {
  color: green;
  margin-top: 20px;
  font-size: 16px;
}

.reset-error {
  border-color: red;
}

/* Demonstrare 2 only*/
@media only screen and (min-width : 768px) {
  .layoutver2 .reset-password .form-horizontal .control-label {
    text-align: left;
  }
  .layoutver2 .reset-password .form-group {
    margin-bottom: 0;
  }

  .layoutver2 .reset-password .form-control {
    height: 24px;
  }
}
/* END - Demonstrare 2 only*/