/* ------------------------------
	// Responsive Floating Label
	------------------------------ */
	
/* Basic Style */
.form-group,
.input-group,
.input-f-label {
    position: relative;   
}


.input-f-label .input-icon {
    margin-top: 10px;
    position: absolute;
    margin-left: 8px;
    z-index: 5;
    left: 0;
}

.input-f-label .glyphicon {
    color: #ccc;
    font-size: 16px;
}


.input-f-label.f-label-active .glyphicon {
    color: #4689be;    
}

.input-group [data-toggle="f-label"] {
    height: 38px;
    padding-top: 15px;
}

.input-f-label [data-toggle="f-label"]{
    height: 34px;
    padding-top: 12px;
		padding-bottom: 0;
		padding-left: 30px;
}

.input-f-label.left-label [data-toggle="f-label"]{
	padding-left: 12px;
	padding-top: 12px;
}

.input-f-label.left-label.active,
.input-f-label.f-label-active {
		font-weight: bold;
}

.input-f-label.left-label.active.inactive{
		font-weight: normal;
}

	.form-group [data-toggle="f-label"] + label,
	.input-group [data-toggle="f-label"] + label,
	.input-f-label [data-toggle="f-label"] + label  {
		font-size: 0.7em;
		left: 12px;
		opacity: 1;
		position: absolute;
		top: 1px;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;

		z-index: 9;
		color: #666;
		font-weight: normal;
	}

.input-f-icon [data-toggle="f-label"] + label {   
	left: 30px;		
}
.input-group.input-f-icon [data-toggle="f-label"] + label {   
	left: 53px;		
}

.input-f-label [data-toggle="f-label"]:required + label,
.form-group [data-toggle="f-label"]:required + label,
.input-group [data-toggle="f-label"]:required + label {
    color: rgb(255, 0, 0);
}

/* Custom Styles */
.input-f-label.input-group-textarea,
.form-group.form-group-textarea,
.input-group.input-group-textarea  {
    background-color: rgb(255, 255, 255);
	border-radius: 1px;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
	margin: 20px 15px ;
	padding: 10px 0 2px;
	position: relative;
}

.input-f-label.form-group-textarea textarea,
.form-group.form-group-textarea textarea,
.input-group.input-group-textarea textarea  {
    height: 34px;
	resize: none;
}
.input-f-label.form-group-textarea label,
.form-group.form-group-textarea label,
.input-group.input-group-textarea label  {
    color: rgb(160, 160, 160);
	/*font-family: 'Roboto', sans-serif;*/
	font-size: 12px;
	font-weight: 500;
}
.input-f-label.form-group-textarea .form-control,
.form-group.form-group-textarea .form-control,
.input-group.input-group-textarea .form-control  {
    border-radius: 0;
	border-width: 0;
	box-shadow: none;
}
.input-f-label.form-group-textarea [data-toggle="f-label"] + label,
.form-group.form-group-textarea [data-toggle="f-label"] + label,
.input-group.input-group-textarea [data-toggle="f-label"] + label  {
	top: 5px;
}

/* Positioning */
.input-f-label [data-toggle="f-label"][data-value=""],
.form-group [data-toggle="f-label"][data-value=""],
.input-group [data-toggle="f-label"][data-value=""]  {
    padding-top: 0;
}

.input-f-label textarea[data-toggle="f-label"][data-value=""] {
    padding-top: 6px;
}

.input-f-label [data-toggle="f-label"][data-value=""] + label,
.form-group [data-toggle="f-label"][data-value=""] + label,
.input-group [data-toggle="f-label"][data-value=""] + label {
	opacity: 0;
	top: -12px;
}

.input-f-label .btn{
	padding: 8px 12px;
}


.f-label-active {
	/*background-color: #FFFFCC;*/
	/*background-color: #faf6dd;*/
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

  .form-group [data-toggle="f-label"] + label,
  .input-group [data-toggle="f-label"] + label,
  .input-f-label [data-toggle="f-label"] + label {
    font-size: 0.8em;
  }
}