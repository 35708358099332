/* Mobile First */

.ProductInfo .pop-message,
.jqm-prodinfo .pop-message {
    display: none;
}

.popup-msg .modal-content {
    /*margin: 0 170px;*/
    background: #000000;
    background: rgba(0, 0, 0, 0.60);
    font-weight: bold;
}

.popup-msg .header {
    color: #fff;
    font-size: 20pt;
    padding: 15px 0 15px 20px;
}

.popup-msg .message {
    margin: 0 15px;
    padding: 15px;
    border-radius: 6px;
    background: #eee;
    font-size: 14pt;
    font-weight: normal;
}

.popup-msg .buttons {
    margin: 15px;
}

.popup-msg .lightbox-close {
    display: none;
}


.pop-reminder-message {
    display: none;
}

.popup-reminder-msg .modal-content {
    /*margin: 0 170px;*/
    background: #000000;
    background: rgba(0, 0, 0, 0.60);
    font-weight: bold;
}

.popup-reminder-msg .header {
    /*color: #fff;*/
    font-size: 20pt;
    padding: 15px 0 15px 20px;
}

.popup-reminder-msg .message {
    margin: 0 15px;
    padding: 15px;
    border-radius: 6px;
    background: #eee;
    font-size: 14pt;
    font-weight: normal;
}

.popup-reminder-msg .buttons {
    margin: 15px;
}

.popup-reminder-msg .lightbox-close { 
    display: none;
}

/* Fix scoll popup for login clubs */
.modal-dialog {
  max-height: 100%;
}
.modal-content {
  margin-bottom: 25px;
}
/* END - Fix scoll popup for login clubs */



/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

    .popup-msg .modal-content {
        margin: 0 170px;
    }



    .popup-reminder-msg .modal-content {
        margin: 0 170px;
    }


}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}


@media only screen and (max-width : 992px) {
    .modal-dialog {
        width: 100%;
        padding-left: 15px;
        padding-right: 25px;
    } 
}

@media only screen and (max-width: 767px) {
    .modal-dialog {
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;
    }

    .modal-content {
        height: 100%;
        border-radius: 0;
        /*color:white;*/
        overflow: auto;
    }
}


@media only screen and (max-width: 767px) {
    .modal.d4-popup  .modal-dialog {
       height: 90%;
       
    }
    
    .modal.d4-popup .modal-content {
        height: 100%;
    }

    .modal.d4-popup .modal-body #lightbox-placeholder.center-placeholder {
        max-height:50vh;
    }
    .modal.d4-popup-quickview .modal-dialog {
        height: 100%;
    }
    .modal.d4-popup.d4-popup-quickview .modal-body #lightbox-placeholder.center-placeholder {
        max-height: 100vh;
    }
}


/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/
