/* ------------------------------
	// Login Contact View
	------------------------------ */


#login-club .rs-table {
  margin-top: 1em;
}

#login-club .lc-header .btn-group {
  float: right;
  padding-top: 1.125em;
  padding-right: 1.125em;
}

#login-club tr,
#login-club tr:disabled,
#login-club tr:hover,
#login-club tr:active {
  text-decoration: none;
  cursor: pointer;
}

#login-club tr:hover {
  background-color: #ededed;
}

#login-club .lc-li-row.lc-even {
  background-image: -moz-linear-gradient(top, #eee, #ccc);
  background-image: -webkit-linear-gradient(top, #eee, #ccc);
  background-image: linear-gradient(to bottom, #eee, #ccc);
}

#login-club .lc-r-col0 {
  font-weight: bold;
}

#login-club .lc-col1, #login-club .lc-r-col1,
#login-club .lc-col2, #login-club .lc-r-col2 {
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}

#login-club .table-bordered > tbody > tr > td {
  border-width: 0;
}

#login-club  .table-bordered.table-striped {
  margin-bottom: 1em;
}

#login-club .lc-col4, #login-club .lc-r-col4 {
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

#login-club .lc-col5,
#login-club .lc-r-col5,
#login-club .lc-col6,
#login-club .lc-r-col6,
#login-club .lc-col7,
#login-club .lc-r-col7 {
  text-align: right;
}

  #login-club .lc-r-col6.lc-r-neg {
    color: red;
  }

#login-club a {
  color: #333;
  font-size: 1em;
}

/*.validation_error {
	color: red;
	border-color: red !important;
}*/

.form-control.validation_error,
.form-control.validation_error:focus {
  border-color: #FF0000;
  box-shadow: inset 0 1px 1px #000000, 0 0 8px #ff0000;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}

.validation_error_msg {
  color: red;
}

.ok {
  border-color: green !important;
}

.green {
  color: green !important;
}

/*#agent-search {
  display: block;
}

#agent-contacts {
  display: none;
}*/


/* MinSide */
.KundeSenterBox div#login-club {
  margin-top: -40px;
}

.KundeSenterBox fieldset.col-sm-12 {
  padding: 0;
}


.KundeSenterBox .lc-header {
  display: none;
}



.KundeSenterBox .btn-primary:hover {
  border-color: #ddd;
  cursor: default;
}


.KundeSenterBox #login-club tr,
.KundeSenterBox #login-club tr:disabled,
.KundeSenterBox #login-club tr:hover,
.KundeSenterBox #login-club tr:active {
  cursor: default;
}


/* ------------------------------
	// END - Login Contact View
	------------------------------ */


/* Agent login 2.0 */
.login-agent fieldset {
  margin: 15px 0;
}

.login-agent h4 {
  margin-top: 25px;
  font-size: 1.5em;
}

.login-agent p {
  font-size: 1.1em;
  margin-bottom: 10px;
}

@media only screen and (min-width : 992px) {
  .login-agent .agent-header1,
  .login-agent .lc-r-col1 {
    max-width: 45px;
  }
}

.login-agent .table-bordered {
  margin-top: 10px;
}

.login-agent .table-striped > tbody > tr,
.login-avs .table-striped > tbody > tr {
  cursor: pointer;
}

  .login-agent .table-striped > tbody > tr:hover,
  .login-agent .table-striped > tbody > tr:active,
  .login-avs .table-striped > tbody > tr:hover,
  .login-avs .table-striped > tbody > tr:active {
    background-color: #3399cc;
    color: #fff;
  }

.login-avs th.lc-col1.avs-header1 {
  background-color: #3399cc;
  color: #fff;
  border-color: #3399cc;
}

.agent-search-result {
  margin-top: 20px;
}
/* END - Agent login 2.0 */




/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/
/*==========  Mobile First Method  ==========*/
/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {
}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {
}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {
  .login-agent #login-club .table-bordered > tbody > tr > td {
    border-width: 1px;
  }

  .login-agent .table-bordered {
    width: 100%;
  }
}



/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
  
  .login-agent .agent-left {
    border-right: #ccc 1px solid;
  }

  .agent-search-result {
    margin-top: 0;
  }
}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {
}

/*==================================================
=  END       Bootstrap 3 Media Queries             =
==================================================*/


.login-avs table {
  margin-bottom: 20px;

}