/* = MultiCase windows - New design for demonstrare 2 (dont use Multicase.css)
-----------------------------------------------------------------------------	*/

.MultiCase-window {
	box-shadow: 0 0 15px 4px #000000;
	box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.6);
	border-radius: 5px;
}

.MultiCase-window-bl,
.MultiCase-window-mc,
.MultiCase-window-br,
.MultiCase-window-bc,
.MultiCase-window-tc,
.MultiCase-window-tr,
.MultiCase-window-tl {
	background-color: #fff;
	border: none;
	padding: 0;
	border-radius: 5px;
}

.MultiCase-window-content {
	background-color: #fff;
	border: none;
	margin-bottom: -10px;
}

.MultiCase-window-ml {
	background-color: #fff;
	border: none;
}

.MultiCase-window-mr{
	background-color: #fff;
	border: none;
}

.MultiCase-window-bl {
	height: 20px;
}

.KonfigWindow,
.KonfigWindow .KonfigWindowContentContainer,
.KonfigWindow .KonfigWindowButtonContainer {
	background-color: #fff;
}

.KonfigWindow .KonfigWindowButtonContainer {	
	height: auto;
	padding: 3px 10px;
}

.MultiCase-window-top-text {
	color: #666;
}

.KonfigWindow .KonfigWindowRightContainer {
	width: 560px;
}

.KonfigWindow .KonfigWindowLeftContainer {
	width: 250px;
	padding: 10px 10px 10px 5px;
	margin-left: 0;
}


/* OrdreBok admin
-----------------------------------------------------------------------------	*/
.ordrebok-admin {
	background-color: #ddd;
	margin: 0;
	padding: 10px;
}

.admin-dates {
	border: 1px solid #999;
	width: 100%;
	max-width: 680px;
	background-color: #f8f8f8;
}

.admin-dates table {
	margin-left: 20px;
}

.admin-dates table:last-child {
	margin-bottom: 10px;
}

.data-text:first-child {
	margin-left: 0;
	margin-top: 10px;
	display: inline-table;
}

.data-text {
	margin-left: 20px;	
}

.data-picker.gaiax-button,
.data-picker .first-child {
	border: none;
	background: none;
}

.data-picker button {
	border: 1px solid #b4b4b4;
	border-bottom-color: #8b8b8b;
	height: 24px;
	background: rgb(254, 254, 254);
	background: -moz-linear-gradient(top, rgba(254, 254, 254, 1) 0, rgba(205, 205, 205, 1) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0, rgba(254, 254, 254, 1)), color-stop(100%, rgba(205, 205, 205, 1)));
	background: -webkit-linear-gradient(top, rgba(254, 254, 254, 1) 0, rgba(205, 205, 205, 1) 100%);
	background: -o-linear-gradient(top, rgba(254, 254, 254, 1) 0, rgba(205, 205, 205, 1) 100%);
	background: -ms-linear-gradient(top, rgba(254, 254, 254, 1) 0, rgba(205, 205, 205, 1) 100%);
	background: linear-gradient(to bottom, rgba(254, 254, 254, 1) 0, rgba(205, 205, 205, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fefefe', endColorstr='#cdcdcd', GradientType=0);
	font-weight: bold;
	color: black;
	text-shadow: 0 1px 0 white;
	border-radius: 3px;
	cursor: pointer;
}

.admin-ordre {
	margin-top: 15px; 
	border: 1px solid #999;
	background-color: #f8f8f8; 
	padding: 20px;
	margin-right: -2px;
}

.admin-carts-result {
	float: right; 
	width: 200px; 
	border: 1px solid gray; 
	padding: 10px; 
	position: relative; 
	top: 15px;
}

.admin-conv-info {
	 width: 375px;
}

.admin-conv-panel {
	margin: 15px 0;
}

.admin-conv-panel2 {
	 margin-top: 40px;
}

.admin-conv-status {
	margin-top: 20px;
}

.admin-report-inner {
	margin: 20px 0;
}

.admin-table {
	width: 100%; 
	padding: 3px; 
	background-color: white;
}

.admin-contact {
	margin-top: 20px;
}

.admin-cust-view {
	margin: 20px 0;
}

.admin-cust-table {
	width: 100%; 
	padding: 3px; 
	background-color: white;
}

.admin-right {
	text-align: right;
  white-space: nowrap;
}

.admin-tot,
.admin-tot-price {
	border-top: 1px solid gray; 
	text-align: right;
}

.admin-error {
	float: left; 
	width: 500px; 
	position: relative; 
	top: 10px;
}

.admin-button {
	text-align: right;
	margin-top: 10px;
	margin-bottom: 10px;
}

.data-picker.gaiax-calendar-container table {
	margin-left: 0;
}

.data-picker.gaiax-calendar-bottom td:first-child .first-child span:after {
	content: "Lukk";
}

.data-picker.gaiax-calendar-bottom td:last-child .first-child span:after {
	content: "Idag";
}

.data-picker.gaiax-datetimepicker-button button {
	max-width: 32px;
}

.admin-conv-panel .gaiax-calendar-container {
	position: fixed;
	z-index: 99;
}